import '../index.css'; 
import '../clients.css'
import { Helmet } from 'react-helmet';
import Navbar from './navbar'
import Faqs from './faqss';
import Carousl from './carousl'
import Principe from './principe'
import Services from './services'
import Clients from './clients';
import Footer from './footer';

const slides = [
    { url: './brands/fr.jpg', alt: 'preview' },
    { url: './brands/elecben.jpg', alt: 'ELECBEN un Monde d`energie' },
    { url: './brands/casa.jpg', alt: 'Casa Colle' },
    { url: './brands/calpa.jpg', alt: 'Calpa maroc' },
    { url: './brands/Amm.jpg', alt: 'AMM' },
    { url: './brands/rma.jpg', alt: 'RMA Coin de l`assurance' },
    { url: './brands/deliz.jpg', alt: 'Deliz Food' },
    { url: './brands/luxlit.jpg', alt: 'Luxlit' },
    { url: './brands/oremaflex.jpg', alt: 'Oremaflex' },
    { url: './brands/Sembaco.jpg', alt: 'Sembaco' },
    { url: './brands/Somdor.jpg', alt: 'Somdor' },
    { url: './brands/ultra.jpg', alt: 'ULTRA ELEC S.A.R.L' },
    { url: './brands/valencerame.jpg', alt: 'Valencerame ceramigue' },
    { url: './brands/hab.jpg', alt: 'Hab Trading' },
    { url: './brands/vital.jpg', alt: 'Vital Auto' },
    { url: './brands/doha.jpg', alt: 'DOHA CERAME' },
    { url: './brands/saber.jpg', alt: 'Saber nour' },
    { url: './brands/mister.jpg', alt: 'Mister Rental Cars' },
    { url: './brands/snl.jpg', alt: 'SNL - Sanitaire Nour Lmanar' },
    { url: './brands/znaga.jpg', alt: 'Znaga win win Carreaux' },
    { url: './brands/elecben.jpg', alt: 'ELECBEN un Monde d`energie' },
    { url: './brands/casa.jpg', alt: 'Casa Colle' },
    { url: './brands/calpa.jpg', alt: 'Calpa maroc' },
    { url: './brands/Amm.jpg', alt: 'AMM' },
    { url: './brands/rma.jpg', alt: 'RMA Coin de l`assurance' },
    { url: './brands/deliz.jpg', alt: 'Deliz Food' },
    { url: './brands/luxlit.jpg', alt: 'Luxlit' },
    { url: './brands/oremaflex.jpg', alt: 'Oremaflex' },
    { url: './brands/Sembaco.jpg', alt: 'Sembaco' },
    { url: './brands/znaga.jpg', alt: 'Znaga win win Carreaux' },
    { url: './brands/doha.jpg', alt: 'DOHA CERAME' },
    { url: './brands/saber.jpg', alt: 'Saber nour' },
    { url: './brands/Somdor.jpg', alt: 'Somdor' },
    { url: './brands/ultra.jpg', alt: 'ULTRA ELEC S.A.R.L' },
    { url: './brands/valencerame.jpg', alt: 'Valencerame ceramigue' },
    { url: './brands/hab.jpg', alt: 'Hab Trading' },
    { url: './brands/vital.jpg', alt: 'Vital Auto' },
    { url: './brands/mister.jpg', alt: 'Mister Rental Cars' },
    { url: './brands/snl.jpg', alt: 'SNL - Sanitaire Nour Lmanar' },
  ];

export default function home(){
    return (
        <>
            <Helmet>
                <title>Accueil - Recouv Management</title>
                <meta name="og:title" property="og:title" content="Recouv Management"></meta>
                <meta name="description" content="Recouv Management offre des solutions efficaces pour le recouvrement de vos créances. Confiez-nous la gestion de vos comptes débiteurs pour récupérer rapidement les sommes qui vous sont dues. Notre équipe expérimentée est là pour résoudre vos problèmes de recouvrement de manière  professionnelle et efficace. Contactez-nous dès maintenant pour obtenir une consultation.." />
                <meta name="keywords" content="recouvrement de créances, société de recouvrement, solutions efficaces, professionnalisme, équipe expérimentée, intégrité, transparence, respect, service de qualité, recherches et enquêtes, recouvrement judiciaire, recouvrement à l'amiable, objectifs financiers" />
            </Helmet>

            <Navbar/>
            
            <Carousl/>

            <Services/>

            <Principe/>

            {/* <Clients /> */}
            <Clients slides={slides} />

            <Faqs/>

            <Footer/>

        </>
    )
}