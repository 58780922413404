import '../index.css'; 


export default function principe(){
    return (
        <>
            <div className='container'>
                <div className='row rowp'>
                    
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 '>
                        <h1 id='principe' className='m-3'>NOS PRINCIPES :</h1>
                        <ul>
                            <li id='li1' className='li1 fw-lighter'>
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dropbox" viewBox="0 0 16 16">
                            <path d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555z"/>
                            </svg>
                                 Respect d’une déontologie et d’un code de conduite professionnel.</li>
                            <li id='li2' className='li1 fw-lighter'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dropbox" viewBox="0 0 16 16">
                            <path d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555z"/>
                            </svg>
                                Adéquation des compétences et des moyens techniques, humains et financiers aux missions confiées et menées.</li>
                            <li id='li3' className='li1 fw-lighter'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dropbox" viewBox="0 0 16 16">
                            <path d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555z"/>
                            </svg>
                                Satisfaction des clients.</li>
                        </ul>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 bgprincipe'>
                     <img src="./2151325862.jpg" class="img-fluid rounded" alt="..."/>

                    </div>
                </div>
            </div>
        </>
    )
}