import { Link } from 'react-router-dom';
import '../index.css'; 


export default function carousl(){
    return (
        <div className='car m-4'>
            <div className='container'>
                <div className='bd'>
                <h1 id='h1'>RECOUV MANAGEMENT</h1>
                <p className='p1'>Vous êtes une Entreprise ?
                    Vos impayés (chèques,effets, factures, remboursement d'un prêt) alourdissent vos frais 
                    Financiers et fragilisent votre 
                    trésorerie?</p>
                <p className='p1'>
                    <span>RECOUV MANAGEMENT</span> est 
                    là pour résoudre vos problèmes en 
                    recouvrant vos <span> CRÉANCES.</span>
                </p>
                <Link to={"/AboutUs"}>
                <button className='toabout'>À propos de nous</button>
                </Link>
                </div>
            </div>
        </div>
    )
}